import { TFunc } from '../i18n';

export const getGoalTargetOptions = (t: TFunc) => {
  return [
    { id: 'water', labelTr: t('water') },
    { id: 'energy_kcal', labelTr: t('energy') },
    { id: 'protein_g', labelTr: t('protein') },
    { id: 'fat_g', labelTr: t('fat') },
    { id: 'carbohydrate_g', labelTr: t('carbohydrate') },
    { id: 'fiber_g', labelTr: t('fiber') },
    { id: 'sugars_g', labelTr: t('sugars') },
    { id: 'calcium_mg', labelTr: t('calcium') },
    { id: 'iron_mg', labelTr: t('iron') },
    { id: 'magnesium_mg', labelTr: t('magnesium') },
    { id: 'phosphorus_mg', labelTr: t('phosphorus') },
    { id: 'potassium_mg', labelTr: t('potassium') },
    { id: 'sodium_mg', labelTr: t('sodium') },
    { id: 'zinc_mg', labelTr: t('zinc') },
    { id: 'vitamin_c_mg', labelTr: t('vitamin c') },
    { id: 'thiamin_mg', labelTr: t('thiamin') },
    { id: 'riboflavin_mg', labelTr: t('riboflavin') },
    { id: 'niacin_mg', labelTr: t('niacin') },
    { id: 'vitamin_b6_mg', labelTr: t('vitamin b6') },
    { id: 'folate_dfe_ug', labelTr: t('folate') },
    { id: 'vitamin_b12_ug', labelTr: t('vitamin b12') },
    { id: 'vitamin_a_rae_ug', labelTr: t('vitamin a rae') },
    { id: 'vitamin_a_iu', labelTr: t('vitamin a iu') },
    { id: 'vitamin_e_mg', labelTr: t('vitamin e') },
    { id: 'vitamin_d2d3_ug', labelTr: t('vitamin d2d3') },
    { id: 'vitamin_d_iu', labelTr: t('vitamin d iu') },
    { id: 'vitamin_k_ug', labelTr: t('vitamin k') },
    { id: 'fattyacids_totalsaturated_g', labelTr: t('saturated fat') },
    { id: 'fattyacids_totalmonounsaturated_g', labelTr: t('mono saturated fat') },
    { id: 'fattyacids_totalpolyunsaturated_g', labelTr: t('poly saturated fat') },
    { id: 'fattyacids_totaltrans_g', labelTr: t('tran fat') },
    { id: 'cholesterol_mg', labelTr: t('cholesterol') },
    { id: 'caffeine_mg', labelTr: t('caffeine') },
    { id: 'cc_whole', labelTr: t('whole grains') },
    { id: 'cc_grains', labelTr: t('refined grains') },
    { id: 'cc_redmeat', labelTr: t('red meat') },
    { id: 'cc_meat', labelTr: t('meat') },
    { id: 'cc_protein', labelTr: t('protein') },
    { id: 'cc_poultry', labelTr: t('poultry') },
    { id: 'cc_pork', labelTr: t('pork') },
    { id: 'cc_shellfish', labelTr: t('shellfish') },
    { id: 'cc_fish', labelTr: t('fish') },
    { id: 'cc_unhealthy_fat', labelTr: t('unhealthy fat') },
    { id: 'cc_healthy_fat', labelTr: t('healthy fat') },
    { id: 'cc_processed', labelTr: t('processed foods') },
    { id: 'cc_ultraprocessed', labelTr: t('ultra processed foods') },
    { id: 'cc_vegetable', labelTr: t('vegetable') },
    { id: 'cc_fruit', labelTr: t('fruit') },
    { id: 'cc_fried', labelTr: t('fried foods') },
    { id: 'cc_legume', labelTr: t('legumes') },
    { id: 'cc_calcium', labelTr: t('calcium-rich foods') },
    { id: 'cc_high_fiber', labelTr: t('high fiber foods') },
    { id: 'cc_whole_foods', labelTr: t('whole foods') },
    { id: 'netcarb_g', labelTr: t('net carbohydrates') },
    { id: 'cc_non_meat_animal_protein', labelTr: t('non-meat animal protein') },
    { id: 'cc_plant_protein', labelTr: t('plant protein') },
    { id: 'cc_nuts_seeds', labelTr: t('nuts and seeds') },
    { id: 'cc_plant_based', labelTr: t('plant-based') },
    { id: 'cc_plant_based_whole', labelTr: t('plant-based whole') },
    { id: 'cc_raw', labelTr: t('raw food') },
    { id: 'cc_additives', labelTr: t('additives') },
    { id: 'cc_gluten_free', labelTr: t('gluten free') },
    { id: 'cc_sugary_beverage', labelTr: t('sugary beverage') },
    { id: 'cc_beverage', labelTr: t('beverage') },
    { id: 'added_sugars_g', labelTr: t('added sugars') },
    { id: 'cc_omega_three', labelTr: t('omega-3') },
    { id: 'cc_red_meat', labelTr: t('red meat') },
    { id: 'glycemic_load', labelTr: t('glycemic load') },
    { id: 'agg_veg_fruit', labelTr: t('vegetables and fruits') },
    { id: 'alcohol', labelTr: t('Alcohol') },
  ];
};
